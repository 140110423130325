import Echo from 'laravel-echo';
import Pusher from 'pusher-js'; // Importando Pusher, que é usado como fallback

window.Pusher = Pusher; // Tornando o Pusher disponível globalmente

const echo = new Echo({
    broadcaster: 'reverb',
    key: process.env.REACT_APP_REVERB_APP_KEY,
    wsHost: process.env.REACT_APP_REVERB_HOST,
    wsPort: process.env.REACT_APP_REVERB_WSPORT,
    wssPort: process.env.REACT_APP_REVERB_WSSPORT,
    forceTLS: process.env.REACT_APP_REVERB_SCHEME === 'https' || false,
    enabledTransports: ['ws', 'wss'],
});

export default echo;
