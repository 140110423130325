import BaseApi from "./BaseApi";
import Cookies from 'js-cookie'

export default class Chat extends BaseApi{
    construct() {}

    static async sendMessage(data){
        let csrfToken = Cookies.get('XSRF-TOKEN')

        let url = Chat.getApiUrl();

        const headers = {
            "Content-Type": "multipart/form-data",
            'X-XSRF-TOKEN': csrfToken,
        };

        return Chat.getAxiosInstance().post(url, data, {headers});
    }

    static async fetchMessages(tripID) {
        try {
            const url = Chat.getApiUrl() + tripID; // Use a mesma URL para buscar mensagens
            const response = await Chat.getAxiosInstance().get(url);
            // Supondo que a resposta seja um array de mensagens
            return response.data; // Verifique a estrutura de resposta
        } catch (error) {
            console.error("Erro ao buscar mensagens:", error);
            return []; // Retorna um array vazio em caso de erro
        }
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/chat/";
    }

}