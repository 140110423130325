import React, { useState, useEffect, useContext } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChair, faUserPlus, faUserMinus, faTrash, faPencil, faCheck } from '@fortawesome/free-solid-svg-icons';
import datagridPT from '../datagrid-pt';
import Trip from '../api/Trip.js';
import Notification from '../api/Notification.js';
import { SessionContext } from '../SessionContext';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

function TripDataGrid({ data, onRowClick, joinTrip, leaveTrip, deleteTrip, refreshTrips, openInformDialog, hideButtons=null}) {
  const { isAuthenticated, session, sessionLoaded } = useContext(SessionContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [columnVisibility, setColumnVisibility] = useState(null);
  const [columns, setColumns] = useState([]);
  const [openEditDateModal, setOpenEditDateModal] = useState(false);
  const [editDateId, setEditDateId] = useState(0);
  const [formData, setFormData] = useState({
    date: '',
    time: '',
    date2: '',
    time2: '',
  });

  useEffect(() => {
    (async () => {
      if (!sessionLoaded) {
        return; // Não faz nada até que a sessão seja carregada
      }
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
        if (window.innerWidth < 780) {
          setColumnVisibility({
            id: false,
            registered: false,
          });
          setColumns(
            [
              {
                "field": "id",
                "headerName": "ID",
                "hide": true
              },
              {
                "field": "date",
                "headerName": "Data",
                "flex": 2,
                "editable": false,
                renderCell: (params) => (
                  <div className="table-date-container">
                    <div>
                      {params.value.day < 10 ? '0' + params.value.day : params.value.day}/{params.value.month < 10 ? '0' + params.value.month : params.value.month}
                      <br />{params.value.time}
                      {params.value.creator == localStorage.getItem('userID') && deleteTrip != null && (session.permissions.some(permission => permission.name === "trips.update") || session.permissions.some(permission => permission.name === "super")) &&
                        <button className="btn-edit-date" onClick={(event) => editDateModal(event, params.id, `${params.value.year}-${params.value.month < 10 ? '0' + params.value.month : params.value.month}-${params.value.day < 10 ? '0' + params.value.day : params.value.day}`, params.value.time, `${params.value.year2}-${params.value.month2 < 10 ? '0' + params.value.month2 : params.value.month2}-${params.value.day2 < 10 ? '0' + params.value.day2 : params.value.day2}`, params.value.time2)} style={{ transform: 'translateY(-6px)' }}>
                          <FontAwesomeIcon icon={faPencil} />
                        </button>
                      }
                    </div>
                  </div>
                ),
              },
              {
                "field": "departure",
                "headerName": "Partida",
                "flex": 3,
                "editable": false
              },
              {
                "field": "destination",
                "headerName": "Destino",
                "flex": 3,
                "editable": false
              },
              {
                "field": "actions",
                "headerName": "",
                "width": 111,
                "editable": false,
                renderCell: (params) => (
                  <div className="table-actions-container">
                    <div>
                      <div className="seats-container">
                        {params.value.seats} <FontAwesomeIcon icon={faChair} />
                      </div>
                      <div className="km-container">
                        {params.value.km} <span>km</span>
                      </div>
                    </div>
                    {!params.value.registered && joinTrip != null && params.value.creator != localStorage.getItem('userID') && (session.permissions.some(permission => permission.name === "candidature.create") || session.permissions.some(permission => permission.name === "super")) &&
                      <button className="btn-primary-circle" onClick={(event) => joinTrip(params.id, event)}>
                        <FontAwesomeIcon icon={faUserPlus} />
                      </button>
                    }
                    {params.value.registered && leaveTrip != null && params.value.creator != localStorage.getItem('userID') && (session.permissions.some(permission => permission.name === "candidature.delete") || session.permissions.some(permission => permission.name === "super")) &&
                      <button className="btn-primary-circle btn-delete" onClick={(event) => leaveTrip(params.id, event)}>
                        <FontAwesomeIcon icon={faUserMinus} />
                      </button>
                    }
                    {params.value.creator == localStorage.getItem('userID') && deleteTrip != null && (session.permissions.some(permission => permission.name === "trips.delete") || session.permissions.some(permission => permission.name === "super")) &&
                      <button className="btn-primary-circle btn-delete" onClick={(event) => deleteTrip(params.id, event)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    }
                    {params.value.creator == localStorage.getItem('userID') && deleteTrip == null &&
                      <button className={"btn-primary-circle " + (params.value.valid == 1 ? "btn-valid" : 'btn-pending-valid')} onClick={(e) => validateTrip(e, params.id, params.value)}>
                        <FontAwesomeIcon icon={faCheck} />
                      </button>
                    }
                  </div>
                ),
              },
              {
                "field": "registered",
                "headerName": "",
                "hide": true
              },
            ]
          );
        } else {
          setColumnVisibility({
            id: false,
            registered: false,
          });
          setColumns(
            [
              {
                "field": "id",
                "headerName": "ID",
                "hide": true
              },
              {
                "field": "date",
                "headerName": "Data",
                "flex": 2,
                "editable": false,
                renderCell: (params) => (
                  <div className="table-date-container">
                    <div>
                      {params.value.day < 10 ? '0' + params.value.day : params.value.day}/{params.value.month < 10 ? '0' + params.value.month : params.value.month}/{params.value.year} - {params.value.time}
                      {params.value.creator == localStorage.getItem('userID') && deleteTrip != null && (session.permissions.some(permission => permission.name === "trips.update") || session.permissions.some(permission => permission.name === "super")) &&
                        <button className="btn-edit-date" onClick={(event) => editDateModal(event, params.id, `${params.value.year}-${params.value.month < 10 ? '0' + params.value.month : params.value.month}-${params.value.day < 10 ? '0' + params.value.day : params.value.day}`, params.value.time, `${params.value.year2}-${params.value.month2 < 10 ? '0' + params.value.month2 : params.value.month2}-${params.value.day2 < 10 ? '0' + params.value.day2 : params.value.day2}`, params.value.time2)}>
                          <FontAwesomeIcon icon={faPencil} />
                        </button>
                      }
                    </div>
                  </div>
                ),
              },
              {
                "field": "departure",
                "headerName": "Partida",
                "flex": 3,
                "editable": false
              },
              {
                "field": "destination",
                "headerName": "Destino",
                "flex": 3,
                "editable": false
              },
              {
                field: "actions",
                headerName: "",
                width: 131,
                editable: false,
                renderCell: (params) => {
                  if (hideButtons === true) return null;
              
                  return (
                    <div className="table-actions-container">
                      <div>
                        <div className="seats-container">
                          {params.value.seats} <FontAwesomeIcon icon={faChair} />
                        </div>
                        <div className="km-container">
                          {params.value.km} <span>km</span>
                        </div>
                      </div>
                      {!params.value.registered && joinTrip != null && params.value.creator !== localStorage.getItem('userID') && 
                       (session.permissions.some(permission => permission.name === "candidature.create") || session.permissions.some(permission => permission.name === "super")) && (
                        <button className="btn-primary-circle" onClick={(event) => joinTrip(params.id, event)}>
                          <FontAwesomeIcon icon={faUserPlus} />
                        </button>
                      )}
                      {params.value.registered && leaveTrip != null && params.value.creator !== localStorage.getItem('userID') && 
                       (session.permissions.some(permission => permission.name === "candidature.delete") || session.permissions.some(permission => permission.name === "super")) && (
                        <button className="btn-primary-circle btn-delete" onClick={(event) => leaveTrip(params.id, event)}>
                          <FontAwesomeIcon icon={faUserMinus} />
                        </button>
                      )}
                      {params.value.creator === localStorage.getItem('userID') && deleteTrip != null && 
                       (session.permissions.some(permission => permission.name === "trips.delete") || session.permissions.some(permission => permission.name === "super")) && (
                        <button className="btn-primary-circle btn-delete" onClick={(event) => deleteTrip(params.id, event)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      )}
                      {params.value.creator === localStorage.getItem('userID') && deleteTrip == null && params.value.valid === 0 && (
                        <button className={"btn-primary-circle " + (params.value.all_confirmed ? ' success-background ' : ' btn-pending-valid ') + 
                                          (params.value.valid === 1 ? "btn-valid" : 'btn-pending-valid')} 
                                onClick={(e) => validateTrip(e, params.id, params.value)}>
                          <FontAwesomeIcon icon={faCheck} />
                        </button>
                      )}
                    </div>
                  );
                },
              },
              {
                "field": "registered",
                "headerName": "",
                "hide": true
              },
            ]
          );
        }
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    })();
  }, [sessionLoaded]);

  const getRowClassName = (params) => {
    return params.row.registered ? 'registered-row' : '';
  };

  const editDateModal = (event, id, date, time, date2, time2) => {
    event.stopPropagation();
    setEditDateId(id);
    console.log("date2", date2);
    setFormData({
      date: date,
      time: time,
      date2: date2,
      time2: time2,
    })
    setOpenEditDateModal(true);
  };

  const validateTrip = (e, id, params) => {
    if(params.all_confirmed == false){
      e.stopPropagation()
      openInformDialog();
      return;
    }else{
      Trip.validate(id)
        .then(({ data }) => {
          console.log('validateTripResponse', JSON.stringify(data));
          refreshTrips();
        }).catch(err => {
          if (err.response.status == 422) {
            console.log('error on validating trip:', err.response.data.errors)
            return;
          }
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('date', value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('formData', formData);
    if (formData.date != '' && formData.time != '' && formData.date2 != '' && formData.time2 != '') {
      const dateTimeStr = `${formData.date}T${formData.time}:00`;
      const dateTime = new Date(dateTimeStr);

      const dateTimeStr2 = `${formData.date2}T${formData.time2}:00`;
      const dateTime2 = new Date(dateTimeStr2);

      const dataArr = {
        hora_saida: formatDateToSQL(dateTime),
        hora_chegada: formatDateToSQL(dateTime2),
      }

      Trip.update(editDateId, dataArr)
        .then(({ data }) => {
          console.log('updateTripResponse', JSON.stringify(data));
          const title = data.title;
          const texto = data.texto;
          const tokens = data.tokens;

          Notification.send(title, texto, tokens);
          refreshTrips();
          setOpenEditDateModal(false);
        }).catch(err => {

          if (err.response.status == 422) {

            console.log('error on updating trip:', err.response.data.errors)

            return;
          }
        });
    }
  };

  function formatDateToSQL(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  if (columnVisibility == null) {
    return null;
  }
  else {
    return (
      <>
        <DataGrid
          rows={data.rows}
          columns={columns}
          localeText={datagridPT}
          autoHeight
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: data.pageSize } },
          }}
          pageSizeOptions={[5, 10, 25]}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          columnVisibilityModel={columnVisibility}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          getRowClassName={getRowClassName}
          disableSelectionOnClick
          onRowClick={onRowClick}
        />
        {openEditDateModal &&
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={() => setOpenEditDateModal(false)}>
                &times;
              </span>
              <form onSubmit={handleSubmit}>
                <div className="floating-label">
                  <input
                    type="date"
                    id="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    placeholder=' '
                    required
                  />
                  <label htmlFor="date" className={formData.date ? 'filled' : ''}>Data de Partida</label>
                </div>
                <div className="floating-label">
                  <input
                    type="time"
                    id="time"
                    name="time"
                    value={formData.time}
                    onChange={handleChange}
                    placeholder=' '
                    required
                  />
                  <label htmlFor="time" className={formData.time ? 'filled' : ''}>Hora de Partida</label>
                </div>
                <div className="floating-label">
                  <input
                    type="date"
                    id="date2"
                    name="date2"
                    value={formData.date2}
                    onChange={handleChange}
                    placeholder=' '
                    required
                  />
                  <label htmlFor="date" className={formData.date2 ? 'filled' : ''}>Data de Chegada</label>
                </div>
                <div className="floating-label">
                  <input
                    type="time"
                    id="time2"
                    name="time2"
                    value={formData.time2}
                    onChange={handleChange}
                    placeholder=' '
                    required
                  />
                  <label htmlFor="time" className={formData.time2 ? 'filled' : ''}>Hora de Chegada</label>
                </div>
                <div className="modal-footer">
                  <button className="btn-primary" type="submit">ALTERAR HORÁRIO</button>
                </div>
              </form>
            </div>
          </div>
        }
      </>
    );
  }
}

export default TripDataGrid;
