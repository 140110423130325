import React, { createContext, useState, useEffect } from 'react';
import User from './api/User.js';
import Cookies from 'js-cookie';

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [session, setSession] = useState(null);
  const [sessionLoaded, setSessionLoaded] = useState(false);

  useEffect(() => {
    const checkAuthentication = () => {
      const csrfToken = Cookies.get('XSRF-TOKEN');
      return !!csrfToken; // Retorna true se o token existir
    };

    const fetchSession = async () => {
      if (!isAuthenticated) return;

      const sessionInvalid = false
      try {
        const { data: sessionData } = await User.getSession().catch((resp) => {

          if(resp.response.status == 401){
            console.log("Invalid session")
            const cookies = document.cookie.split("; ");
            cookies.forEach(cookie => {
                const eqPos = cookie.indexOf("=");
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
            });
            window.location.href = '/login'
            sessionInvalid = true
          }
        });
        if(!sessionInvalid)
          setSession(sessionData);
      } catch (err) {
        console.error('Error fetching session:', err);
      }
      setSessionLoaded(true);
    };

    if (checkAuthentication()) {
      setIsAuthenticated(true);
      fetchSession();
    }
    else{
      setIsAuthenticated(false);
      if(window.location.pathname != '/'){
        if(!window.location.href.includes('verify-email'))
          window.location.href = process.env.REACT_APP_FULL_DOMAIN+'/';
      }
    }
  }, [isAuthenticated]);

  return (
    <SessionContext.Provider value={{ isAuthenticated, session, sessionLoaded }}>
      {children}
    </SessionContext.Provider>
  );
};
