import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faComments, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Chat from '../api/Chat.js';
import echo from '../echo.js';
import User from '../api/User.js';

let channel;

const ChatButton = () => {

  const [showChats, setShowChats] = useState(false);
  const [showTripChat, setShowTripChat] = useState(false);
  const [trips, setTrips] = useState([]);
  const [currentTrip, setCurrentTrip] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const messagesEndRef = useRef(null);
  const sender = localStorage.getItem('userID');

  const getTrips = async () => {
    try {
      const response = await User.trips();
      setTrips(response.data);
    } catch (err) {
      if (err.status === 422) {
        console.log('error on listing trips:', err.data.errors);
      }
    }
  }

  const handleOnTripClick = (trip) => {
    setShowTripChat(true);
    setCurrentTrip(trip);
    listenForChat(trip);
    scrollToBottom();
  }

  const listenForChat = (trip) => {
    channel = echo.channel(`chat.${trip.id}`);

    channel.listen('MessageSent', (e) => {
      // Override the "is_owner" flag for the broadcasting
      e.message.is_owner = e.message.sender === sender;

      console.log("Nova mensagem recebida:", e.message); // Adicione este log
      // Adiciona a nova mensagem recebida ao estado
      setMessages((prevMessages) => [...prevMessages, e.message]);
      setNewMessage('');
      scrollToBottom(); // Rolagem para o final quando uma nova mensagem é recebida
    });
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      const list = messagesEndRef.current;
      list?.scrollTo({ behavior: 'smooth', top: list?.scrollHeight })
    }, 100);
  }

  const handleOnSendMessage = async (e) => {
    e.preventDefault();

    const messageData = {
      tripId: currentTrip.id,
      sender: sender,
      content: newMessage,
    };

    try {
      // Chama a função sendMessage da API
      await Chat.sendMessage(messageData);
    } catch (err) {
      console.error("Erro ao enviar a mensagem:", err);
    }
  }

  const handleBackButton = () => {
    setShowTripChat(false);
    setCurrentTrip(null);
    setShowChats(true)

    channel.stopListening('MessageSent')
  }

  useEffect(() => {
    (async () => {
      if (trips.length > 0) return;

      await getTrips();
    })();
  }, []);

  useEffect(() => {
    // Aqui você pode buscar as mensagens existentes da sua API quando o componente for montado
    (async () => {
      if (! currentTrip) return;

      // Supondo que você tenha uma função para buscar mensagens
      setMessages(await Chat.fetchMessages(currentTrip.id)); // Implementar a função fetchMessages
    })();
  }, [currentTrip]);

  return (
    <div id='chat-card'>
      {showChats && <div id='chat-list'>
        <div className='d-flex align-items-center justify-content-between px-3 py-3'>
          <div>
            {showTripChat && <button
              className='btn btn-default btn-sm'
              onClick={handleBackButton}
            >
              <FontAwesomeIcon icon={faChevronLeft} size='lg' />
            </button>}
            <span className='h5 mb-0'>
              {currentTrip ? `${currentTrip.partida} - ${currentTrip.destino}` : 'Minhas Viagens'}
            </span>
          </div>
          <button
            className='btn btn-close'
            onClick={() => setShowChats(false)}
          />
        </div>
        <hr className='m-0' />
        {!showTripChat && <div className="list-group list-group-flush">
          {trips.map(trip => (
            <button
              key={trip.id}
              onClick={() => handleOnTripClick(trip)}
              className="list-group-item list-group-item-action"
            >
              {trip.partida} - {trip.destino}
            </button>
          ))}
        </div>}

        {showTripChat && <div ref={messagesEndRef} id="chat-messages-list" className='d-flex flex-column p-3 overflow-x-hidden overflow-y-auto'>
          {messages.map((message, i) => (
            <div key={message.id} className={`align-self-${message.is_owner ? 'end' : 'start'}`}>
              <p className={`chat-message-${message.is_owner ? 2 : 1} mb-1`}>
                {message.content}
              </p>
              {message.is_owner !== messages[i + 1]?.is_owner &&
                <p className={`message-sender text-${message.is_owner ? 'end' : 'start'}`}>
                  {message.user.name}
                </p>
              }
            </div>
          ))}
        </div>}
        {showTripChat && <div className='d-flex w-100 mt-auto'>
          <form onSubmit={handleOnSendMessage} className='d-flex w-100'>
            <input
              value={newMessage}
              onInput={e => setNewMessage(e.target.value)}
              type="text"
              id='message-field'
              className='px-3'
              placeholder='Send a message...'
            />
            <button
              className='btn-primary'
              onClick={handleOnSendMessage}
            >
              <FontAwesomeIcon icon={faPaperPlane} size='xl' />
            </button>
          </form>
        </div>}
      </div>}


      <button
        className="btn-chat"
        onClick={() => setShowChats(!showChats)}
      >
        <FontAwesomeIcon icon={faComments} />
      </button>
    </div>
  );
};

export default ChatButton;
